"use client";

import { PackageListData } from "@/types/page";
import { useEffect, useRef, useState } from "react";
import styles from "./css/MostPreferredPackages.module.scss";

import { usePathname } from "next/navigation";
import { formatENetValues, formatSlug, insertENetValues } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import { getRemainingDays, initPackageCategories, initSelectedCategory, loadMore } from "./functions";
import PackageListSelector from "./PackageListSelector/PackageListSelector";
import JsonLd from "../JsonLd/JsonLd";
import { linkUrl, rtbhSender } from "@/helpers";
import BuyInfoDialog from "../BuyInfoDialog/BuyInfoDialog";

/*
RowsPerPage should either be -1 or a multiple of 3
If not it will be converted to a multiple of 3
If no rowsPerPage value is sent, it will be considered as 3

Case rowsPerPage = -1: list all items, don'show "load more" button
Case else: list indicated amount of items and show a "load more" button if there are more items

NoneSwiping is a boolean that indicates if the package list should be swipable or not when view size is mobile
*/
export default function GroupedPackages({ packageData, rowsPerPage, noneSwiping, className, gtmListId }: {
  readonly packageData: PackageListData,
  readonly rowsPerPage?: number,
  readonly noneSwiping?: boolean,
  readonly className?: string,
  readonly gtmListId?: string,
}) {
  const dialogRef = useRef<any>(null);

  let allPackages: Record<string,any> = [];
  packageData.package_categories?.forEach((item: Record<string,any>) => { allPackages = allPackages.concat(item.packages); });

  // Convert rowsPerPage to a multiple of 3
  const rpp = Math.ceil(Math.max(rowsPerPage ?? 3) / 3) * 3;

  const pathname = usePathname();

  const data = { ...packageData };
  
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ selectedCategory, setSelectedCategory ] = useState(initSelectedCategory(packageData));
  const [ packageCategories, setPackageCategories] = useState(initPackageCategories(data.package_categories, rpp));
  const [ remainingDayCount, setRemainingDayCount] = useState<number>();
  
  const selectCategory = (index: number) => {
    packageData.selectedCategory = index;
    setSelectedCategory(index);
  };

  const loadMoreHandler = (page: number) => {
    loadMore(page, rpp, selectedCategory, setPackageCategories);
  };

  useEffect(() => {
    setRemainingDayCount(getRemainingDays(packageCategories[selectedCategory]?.exam?.date));

    setIsLoaded(true);
  }, [ packageCategories, selectedCategory ]);
  
  useEffect(() => {
    rtbhSender({ eventType: 'category', categoryId: packageData?.slug });
  }, [ packageData?.slug ]);
  
  return (
    <>
      <BuyInfoDialog ref={dialogRef} />
      
      <div className={`container ${styles['our-packages']} ${className ?? ''} ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0 min-h-[600px]'}`}>
        <h2 className={`flex justify-between items-center text-2xl leading-tight font-bold ${remainingDayCount ? '-mt-2 mb-1' : 'mb-1'}`}>
          { packageData.our_packages_caption }
          {
            remainingDayCount && (
              <span className="hidden lg:flex flex-col items-center sm:text-base text-charcoal px-8 py-6 bg-white shadow-lg rounded-lg cursor-default -mt-6">
                <div className="text-center text-xl font-semibold mb-2">
                  {
                    packageData?.exam?.remaining_caption
                      ? packageData?.exam?.remaining_caption
                      : _e('Sınava Kalan')
                  }
                </div>
                <div className="text-center text-4xl font-bold text-medium-purple">{ remainingDayCount } { _e('Gün') }</div>
              </span>
            )
          }
        </h2>

        <div className={`flex items-end gap-6 lg:gap-7 ${packageCategories?.length < 2 ? 'hidden' : ''}`}>
          {
            packageCategories.map((item: any, index: number) => {
              if(!item.packages?.length) return null;

              return (
                <div
                  key={item.id}
                  className={`
                    relative flex justify-start mb-2 py-4
                    text-sm sm:text-base
                    font-semibold cursor-pointer select-none
                    after:absolute after:bottom-0 after:left-0 after:right-0 after:transition-all
                    w-full flex-shrink transition-all
                    ${ selectedCategory === index ? 'text-honolulu-blue after:h-[6px] after:bg-honolulu-blue': 'text-cadet-gray after:h-[3px] after:bg-cadet-gray' }
                  `}
                  onClick={ () => selectCategory(index) }
                >
                  { item?.caption }
                </div>
              );
            })
          }
        </div>
        
        <div className="max-w-full w-full">
          <div className="lg:mx-0">
            <PackageListSelector
              slugPrefix={formatSlug(packageCategories[selectedCategory]?.slug ?? '', packageData?.packages_slug ?? pathname)}
              noneSwiping={noneSwiping}
              data={packageCategories[selectedCategory]}
              loadMoreHandler={loadMoreHandler}
              rowsPerPage={rowsPerPage}
              gtmListId={gtmListId}
              dialogRef={dialogRef}
            />

            <JsonLd data={{
              "@context": "https://schema.org",
              "@type": "ProductCollection",
              "itemListElement": allPackages?.map((item: Record<string,any>, index: number) => {
                return {
                  "@type": "Product",
                  "position": index + 1,
                  "name": item.caption,
                  "image": linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', item.image_url),
                  "description": insertENetValues(item?.content ?? '', formatENetValues({ ...(item?.curriculums?.credits ?? {}), ...{ Sezon: item.season, 'Paket Adı': item.caption } }), true),
                  "brand": {
                    "@type": "Brand",
                    "name": "Doping Hafıza"
                  },
                  "offers": {
                    "@type": "Offer",
                    "priceCurrency": "TRY",
                    "price": item.price,
                    "url": formatSlug(item.slug, formatSlug(item?.package_category?.slug ?? '', packageData?.packages_slug ?? ''))
                  }
                };
              })
            }} />

          </div>
        </div>
      </div>
    </>
  );
}
