import { videoFunctions } from "@/components/HomeSlider/functions";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer";
import { linkUrl, purify } from "@/helpers";
import { useState } from "react";

export default function SpecList({ specs }: {
  readonly specs: Record<string,any>[]
}) {
  const [ videos, setVideos ] = useState<{ id: string, video: HTMLVideoElement }[]>([]);
  const fn = videoFunctions({ videos, setVideos });

  return (
    <>
      {
        specs?.map((item, index) => (
          <div key={item.id} className={`bg-white p-4 lg:p-6 rounded-lg shadow-sm select-none flex flex-col ${index % 2 ? 'lg:flex-row-reverse' : 'lg:flex-row'} gap-0 lg:gap-4 xl:gap-6 mb-5 [&:last-child]:mb-0`}>
            <div className="block lg:hidden text-honolulu-blue text-xl mb-2 font-semibold">{ item?.caption }</div>
            <div className="w-full lg:w-1/2">
              <VideoPlayer
                src={ linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/', item?.video_url ?? '') ?? '' }
                poster={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/videogorsel/', item?.video_poster ?? '') ?? '' }
                onAdd={fn.addVideo}
                onRemove={fn.removeVideo}
              />
            </div>
            <div className="w-full lg:w-1/2 mt-2 lg:mt-0">
              <div className="hidden lg:block text-honolulu-blue text-xl lg:mb-2 xl:mb-1 font-semibold">{ item.caption }</div>
              <div className="[&>p]:py-2 lg:text-sm xl:text-base" dangerouslySetInnerHTML={purify(item?.content)}></div>
            </div>
          </div>
        ))
      }
    </>
  );
}