"use client";
import { useEffect, useState } from "react";
import "./css/ilkokul.scss";
import ItemBox from "./ItemBox";
import ProductCard from "./ProductCard";
import Hero from "./Hero";
import _e from "@/helpers/texts";
import Image from "next/image";
import CommonButton from "./CommonButton";
import ContactDialog from "./ContactDialog";
import { linkUrl, rtbhSender } from "@/helpers";
import { getECommerceId } from "@/helpers/formatters";

export default function Ilkokul({ data, contracts }: {
  readonly data: Record<string,any>,
  readonly contracts: Record<string,any>,
}) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ isMobile, setIsMobile ] = useState(false);
  const [ selectedDepartment, setSelectedDepartment ] = useState<string>('Ürün Belirtilmemiş');
  const [ contactOpen, setContactOpen ] = useState<boolean>(false);

  const boxes = data.boxes?.slice(0,9)?.map((item: Record<string,any>) => {
    return {
      "key": item.id,
      "caption": item.title,
      "content": item.content,
      "image": linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/123sinif/', item.image_url ?? ''), // ! linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/ilkokul/', item.image_url),
      "fallback": "/assets/images/ilkokul/box-items/box-image.png", // ! item?.image_url ? "/assets/images/ilkokul/box-items/" + item.image_url : "/assets/images/ilkokul/box-items/box-image.png",
      "altText": item?.title ?? "Doping Hazıza",
      "decoration": item?.decoration,
    };
  });

  let cards: Record<string,any>[] = [];
  data?.products?.forEach((item: Record<string,any>) => {
    if(item?.packages.length >= 2) {
      let card = {
        key: item.id,
        onMarket: item.on_market,
        image: linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/files/123sinif/', item.image_url ?? ''), //"/assets/images/ilkokul/products/" + item?.image_url,
        caption: item.caption,
        bg_color: item?.bg_color,
        contents: item.packages[0].card_props?.map((content: Record<string,any>) => {
          return { key: content.id, text: content.caption };
        }),
        products: [
          {
            key: item.packages[0].id,
            caption: item.packages[0].caption,
            price: item.packages[0].pricing?.packagePrice ?? item.price,
            cancelledPrice: item.packages[0].pricing?.packageStrikePrice ?? item.cancelled_price,
            external_id: item.packages[0].external_id,
            url: `/${data.packages_slug}/${item.packages[0].package_category.slug}/${item.packages[0].slug}`,
            ecommerceid: getECommerceId(item.packages[0]),
            category_name: item.packages[0].package_category?.caption,
          },
          {
            key: item.packages[1].id,
            caption: item.packages[1].caption,
            price: item.packages[1].pricing?.packagePrice ?? item.price,
            cancelledPrice: item.packages[1].pricing?.packageStrikePrice ?? item.cancelled_price,
            isRecommended: true,
            external_id: item.packages[1].external_id,
            url: `/${data.packages_slug}/${item.packages[1].package_category.slug}/${item.packages[1].slug}`,
            ecommerceid: getECommerceId(item.packages[1]),
            category_name: item.packages[1].package_category?.caption,
          },
        ],
      };

      return cards.push(card);
    }
  });

  const [ selected, setSelected ] = useState<string|number>(cards?.[0]?.key ?? 0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window?.innerWidth < 1024);
    };
    
    handleResize();

    setIsLoaded(true);
  
    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    rtbhSender({ eventType: 'category', categoryId: data?.slug });
  }, [ data?.slug ]);

  return (
    <main className={`__ilkokul ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
      <Hero
        text={data?.hero_caption ?? "Doping Hafızada <br/> 1. 2. 3. sınıf ile <br/> öğrenciniz sağlam <br/> temeller atsın!"}
        video_src={data?.hero_video_url ? (linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/123sinif/', data.hero_video_url) as string) : "https://media.dopinghafiza.com/ornek-videolar/123sinif/123tanitim/media.m3u8"}
        video_poster={data?.hero_video_poster ? (linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/ilkokul/', data.hero_video_poster) as string) : `/assets/images/ilkokul/hero-video-poster.png'}`}
      />

      <div id="cards" className="relative container">
        {/* Item Boxes */}
        <section>
          <h2 className="pt-[30px] lg:pt-[60px] text-[#1C1442] text-center text-[32px] lg:text-[48px] font-bold">
            { _e('Doping Hafızada Neler Var') }
          </h2>

          <div className="relative -mx-[15px] flex flex-wrap">
            {
              boxes?.map((box: Record<string,any>, index: number) => (
                <div key={box.key} className="relative px-[15px] w-full md:w-1/2 lg:w-1/3 my-[40px] md:my-[60px]">
                  <div className="relative z-10">
                    <ItemBox
                      number={index + 1}
                      caption={box.caption}
                      content={box.content}
                      image={box.image}
                      fallback={box.fallback}
                      altText={box.altText}
                    />
                  </div>

                  {
                    (() => {
                      switch (box.decoration) {
                      case "kral_sakir":
                        return (
                          <>
                            <Image
                              src={"/assets/images/ilkokul/kral-sakir-001.png"}
                              alt={box.caption}
                              loading="lazy" //! priority={true}
                              width={85}
                              height={80}
                              className="absolute select-none z-0 -top-[12px] -right-[12px] lg:-right-[24px] xl:right-0"
                            />
                            <Image
                              src={"/assets/images/ilkokul/kral-sakir-002.png"}
                              alt={box.caption}
                              loading="lazy" //! priority={true}
                              width={90}
                              height={76}
                              className="absolute select-none z-0 bottom-[8px] -left-[52px]"
                            />
                            <Image
                              src={"/assets/images/ilkokul/kral-sakir-003.png"}
                              alt={box.caption}
                              loading="lazy" //! priority={true}
                              width={48}
                              height={89}
                              className="absolute select-none z-0 -bottom-[55px] -right-[8px]"
                            />
                          </>
                        );
                      case "oxford":
                        return (
                          <>
                            <Image
                              src={"/assets/images/ilkokul/oxford-001.png"}
                              alt={box.caption}
                              loading="lazy" //! priority={true}
                              width={178}
                              height={60}
                              className="absolute select-none z-0 -bottom-[68px] right-0 left-0 m-auto"
                            />
                          </>
                        );
                      case "tamamlayici":
                        return (
                          <>
                            <Image
                              src={"/assets/images/ilkokul/tamamlayici-001.png"}
                              alt={box.caption}
                              loading="lazy" //! priority={true}
                              width={288}
                              height={276}
                              className={`absolute select-none z-0 -bottom-[200px] -right-[130px] ${index % 2 === 0 ? 'md:-left-[130px] md:right-auto' : ''} lg:-right-[130px] lg:left-auto`}
                            />
                          </>
                        );
                      case "birebir_veli":
                        return (
                          <>
                            <Image
                              src={"/assets/images/ilkokul/birebir-veli-001.png"}
                              alt={box.caption}
                              loading="lazy" //! priority={true}
                              width={284}
                              height={273}
                              className={`absolute select-none z-0 -bottom-[166px] -left-[130px] ${index % 2 === 1 ? 'md:-right-[130px] md:left-auto' : ''} lg:-left-[130px] lg:right-auto`}
                            />
                          </>
                        );
                      }
                    })()
                  }
                </div>
              ))
            }

          </div>

        </section>
      </div>

      {/* Product Cards */}
      <section id="products">
        <div className={`container hidden lg:block`}>
          <h2 className="pt-[60px] pb-[40px] text-[#1C1442] text-center text-[48px] font-bold">
            { _e('Sınıflar') }
          </h2>
        </div>
        
        <div className={`${isMobile ? '' : 'container'}`}>
          <div className="relative w-full max-w-[420px] lg:max-w-full m-auto">
            <div className="relative flex lg:hidden justify-stretch gap-[8px] bg-black p-1">
              {
                cards.map((card: Record<string,any>) => (
                  <div key={card.key} className="w-full">
                    <CommonButton width="100%" color={selected === card.key ? 'purple' : 'transparent'} onClick={ () => setSelected(card.key) } className="px-2">
                      { card.caption }
                    </CommonButton>
                  </div>
                ))
              }
            </div>

            <div className="relative flex justify-stretch gap-[30px] min-h-[808px]">
              {
                cards.map((card: Record<string,any>) => (
                  <div key={card.key} className={`${!isMobile || selected === card.key ? '' : 'hidden'} ${isMobile ? 'w-full' : ''}`}>
                    <ProductCard
                      isMobile={isMobile}
                      onMarket={card.onMarket}
                      image={card.image}
                      contentList={card.contents}
                      productList={card.products}
                      caption={card?.caption}
                      bgColor={card?.bg_color}
                      openContact={(dep: string) => {
                        setSelectedDepartment(dep);
                        setContactOpen(true);
                      }}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
      
      {
        contactOpen && (
          <ContactDialog
            contracts={contracts}
            department={selectedDepartment}
            close={() => setContactOpen(false)}
          />
        )
      }

    </main>
  );
}
