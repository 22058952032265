"use client";

import Image from "next/image";
import _e from "@/helpers/texts";
import { fetchMailer } from "@/helpers/fetchers/api-fetcher";
import { useEffect, useRef, useState } from "react";
import CommonButton from "./CommonButton";
import {Dialog, DialogContent} from "@/components/ui/Dialog";
import { ScrollArea } from '@/components/ui/ScrollArea';
import { purify } from "@/helpers";
import "./css/user-form-dialog.scss";
import SimpleToaster from "@/components/SimpleToaster/SimpleToaster";
import DPIcon from "@/components/DPIcon/DPIcon";

interface IFormData {
  name: string;
  phone: string;
  department: string;
  message: string;
}

interface IFormErrors {
  name?: string;
  phone?: string;
  message?: string;
}

export default function ContactDialog({ contracts, department, close }: {
  readonly contracts: Record<string,any>,
  readonly department: string,
  readonly close: Function,
}) {
  const toasterRef = useRef<any>(null);
  const [ sending, setSending ] = useState<boolean>(false);

  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  
  const [ isShort, setIsShort ] = useState<boolean>(false);
  const [ isMobile, setIsMobile ] = useState<boolean>(false);
  const [ isSent, setIsSent ] = useState<boolean>(false);

  const [contractDialog, setContractDialog] = useState<boolean>(false);
  const [consentDialog, setConsentDialog] = useState<boolean>(false);
  const [isConsentChecked, setIsConsentChecked] = useState<boolean>(false);

  const nameRegex = /^[A-Za-zÇŞĞÜÖİçşğüöı]+( [A-Za-zÇŞĞÜÖİçşğüöı]+){1,2}$/;
  const phoneRegex = /^\([1-9][0-9][0-9]\) \d{3} \d{2} \d{2}$/;
  const messageRegex = /^.{1,1023}$/;

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    phone: '',
    message: '',
    department,
  });

  // Hatalar için state
  const [errors, setErrors] = useState<IFormErrors>({});

  const formatPhoneNumber = (input:any) => {
    let digits = input.replace(/\D/g, '');
    if(!digits) return '';

    if(digits.slice(0,1) === '9') digits = digits.substring(1);
    if(!digits) return '';

    digits = (Number(digits)).toString();
  
    if (digits.length < 4) return digits;
    if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    if (digits.length < 9) return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6, 8)} ${digits.slice(8, 10)}`;
  };

  const handleChange = (e:any) => {
    const { name, value } = e.target;

    if (name === 'name' && nameRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, name: undefined }));
    }

    if (name === 'phone') {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevState) => ({ ...prevState, [name]: formattedValue }));
  
      setErrors((prevState) => ({ ...prevState, phone: undefined }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }

    if (name === 'message' && messageRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, message: undefined }));
    }
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();

    const newErrors: IFormErrors = {};

    if (!formData.name || !nameRegex.test(formData.name)) {
      newErrors.name = _e("Lütfen geçerli bir ad soyad giriniz.");
    }
    if (formData.name?.length > 255) {
      newErrors.name = _e("İsim bilgisi :len karakterden uzun olamaz.", { len: 255 });
    }
    
    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      newErrors.phone = _e("Lütfen geçerli bir telefon numarası giriniz.");
    }

    if (!formData.message) {
      newErrors.message = _e("Lütfen bir mesaj giriniz.");
    }
    if (formData.message?.length > 1023) {
      newErrors.name = _e("Mesaj :len karakterden uzun olamaz.", { len: 1023 });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});

      if(!isConsentChecked) {
        toasterRef.current.publish(_e('Lütfen Açık Rıza Metni\'ni kabul ediniz.'));
        setSending(false);
        return false;
      }
  
      (async () => {
        setSending(true);

        const res = await fetchMailer(formData);

        if (res.status === 200) {
          const response = await res.data;
          if(response?.success) {
            setIsSent(true);
            
            let interval = Number(process.env.NEXT_PUBLIC_INTERVAL_PER_CONTACT_FORM_IN_MINUTES ?? 1);
            if(!interval || isNaN(interval)) interval = 1;
            interval = Math.max(1, interval);
            setTimeout(() => {
              setSending(false);
              setIsSent(false);
            }, interval * 60000);
          } else if(response?.error) {
            toasterRef.current.publish(response.error);
            setSending(false);
          } else {
            toasterRef.current.publish(_e('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'));
            setSending(false);
          }
        } else {
          toasterRef.current.publish(_e('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'));
          setSending(false);
        }
      })();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsShort(window?.innerHeight < 900);
      setIsMobile(window?.innerWidth < 620);
    };

    handleResize();

    setTimeout(() => setIsLoaded(true), 100);

    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`contact-dialog custom-scroll fixed flex justify-center items-center transition-opacity ${isLoaded ? 'opacity-1' : 'opacity-0'} ${isMobile ? 'z-[98] top-[74px] bg-white' : 'z-[99] top-0 bg-[rgba(0,0,0,.3)]'} right-0 bottom-0 left-0 w-full overflow-auto`}>
      <div className={`${isMobile ? 'absolute top-0 right-0 bottom-0 left-0 m-auto w-full' : 'relative w-[530px] py-1 rounded-[76px]'} max-w-full max-h-full bg-slate-300`}>
        {
          isMobile ? (
            <>
              <div className={`absolute top-[20px] left-[15px] cursor-pointer z-10`}>
                <Image
                  src={"/assets/images/ilkokul/arrow-left.svg"}
                  alt={"Doping Hafıza"}
                  loading="lazy" //! priority={true}
                  width={24}
                  height={20}
                  onClick={() => close()}
                />
              </div>
            </>
          ) : (
            <>
              <div className={`absolute -top-[6px] -right-[4px] cursor-pointer z-10`}>
                <CommonButton color="red" width="60px" height="60px" small={true} onClick={close}>
                  <DPIcon icon="close" className="text-3xl" />
                </CommonButton>
              </div>
            </>
          )
        }

        {
          isSent ? (
            <div className={`contact-dialog-body ${isMobile ? 'absolute top-0 right-0 bottom-0 left-0 m-auto w-full' : 'relative w-[530px] rounded-[76px]'} max-w-full flex flex-col items-center ${isShort ? 'pt-[45px] pb-[30px]' : 'pt-[60px] pb-[60px]'} px-[30px]`}>
              <div className={`__success-icon flex justify-center items-center rounded-full bg-medium-aquamarine w-[100px] h-[100px] mt-4 ${isShort ? 'mb-10' : 'mb-14'} mx-auto`}>
                <DPIcon icon="check" className="text-white text-5xl" />
              </div>

              <div>
                <h2 className={`text-[#1C1442] text-[20px] font-semibold my-2 text-center`}>{ _e('Tebrikler') }</h2>
                <p className={`text-center text-[#686868]`}>
                  { _e('Bilgilerinizi bizimle paylaştığınız için teşekkürler. Ekim ayında sizi arayacağız.') }
                </p>
              </div>

              <div className="flex justify-end w-full mt-10">
                <CommonButton width="100%" color="orange" onClick={close} className={`px-2`}>
                  { _e('Geri Dön') }
                </CommonButton>
              </div>
            </div>
          ) : (
            <div className={`contact-dialog-body ${isMobile ? 'absolute top-0 right-0 bottom-0 left-0 m-auto w-full px-[30px] justify-evenly' : 'relative w-[530px] rounded-[76px] px-[90px]'} max-w-full flex flex-col items-center ${isShort ? 'pt-[30px]' : 'pt-[60px]'} pb-[30px]`}>
              <div className="flex flex-col items-center">
                <Image
                  src={"/assets/images/ilkokul/calendar.png"}
                  alt={"Doping Hafıza"}
                  loading="lazy" //! priority={true}
                  width={isShort ? 81 : 132}
                  height={isShort ? 80 : 130}
                  className=""
                />

                <h2 className={`text-[#1C1442] text-center ${isShort ? 'text-[16px]' : 'text-[20px]'} font-semibold my-2`}>{ _e('Ürün İçerikleri Hazırlanmaktadır') }</h2>
                <p className={`text-center text-[#686868] ${isShort ? 'text-sm' : 'text-base'}`}>
                  { _e('Ekim ayında sizi arayalım, avantajlı fiyatlardan yararlanın.') }
                </p>
              </div>

              <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                <div className={`${isShort ? 'mt-[14px]' : 'mt-[30px]'}`}>
                  <label htmlFor="name" className="text-[#949BA5] text-sm">
                    { _e('Adı & Soyadı') }
                  </label>
                  <input 
                    type="text" 
                    maxLength={255}
                    className={`w-full border rounded-lg py-2 px-4 h-12 ${errors.name ? 'border-lava bg-[#FDECEA]' : 'border-[#BDBDBD] bg-[#FAF9FF]'}`} 
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={formData.name}
                  />
                  {errors.name && <p className="error-message text-xs text-lava mt-[2px] h-0 overflow-visible">{errors.name}</p>}
                </div>

                <div className={`${isShort ? 'mt-[20px]' : 'mt-[30px]'}`}>
                  <label htmlFor="phone" className="text-[#949BA5] text-sm">
                    { _e('Telefon') }
                  </label>
                  <input 
                    type="text"
                    className={`w-full border rounded-lg py-2 px-4 h-12 ${errors.name ? 'border-lava bg-[#FDECEA]' : 'border-[#BDBDBD] bg-[#FAF9FF]'}`} 
                    id="phone"
                    name="phone"
                    maxLength={255}
                    onChange={handleChange}
                    value={formData.phone}
                    max={10}
                  />
                  {errors.phone && <p className="error-message text-xs text-lava mt-[2px] h-0 overflow-visible">{errors.phone}</p>}
                </div>

                <div className={`${isShort ? 'mt-[20px]' : 'mt-[30px]'}`}>
                  <label htmlFor="message" className="text-[#949BA5] text-sm">
                    { _e('Notunuz') }
                  </label>
                  <textarea
                    className={`w-full border rounded-lg py-2 px-4 ${isShort ? 'h-[66px]' : 'h-[88px]'} ${errors.name ? 'border-lava bg-[#FDECEA]' : 'border-[#BDBDBD] bg-[#FAF9FF]'}`} 
                    id="message"
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                    maxLength={1023}
                  ></textarea>
                  {errors.message && <p className="error-message text-xs text-lava -mt-1 h-0 overflow-visible">{errors.message}</p>}
                </div>

                <div className="text-xs text-[#949BA5] mt-6 mb-2">
                  * Kişisel verileriniz <span onClick={() => setContractDialog(true)} className="underline cursor-pointer font-medium text-[#FC6A00]">Aydınlatma Metni</span> kapsamında işlenmektedir.
                </div>

                <div className="flex justify-start items-start gap-2 text-xs text-[#949BA5] my-2" onClick={ () => setIsConsentChecked((current: boolean) => { return !current; }) }>
                  {
                    isConsentChecked ? (
                      <Image
                        src={"/assets/images/ilkokul/checkbox-checked.svg"}
                        alt={"Doping Hafıza"}
                        loading="lazy" //! priority={true}
                        width={24}
                        height={24}
                        className=""
                      />
                    ) : (
                      <Image
                        src={"/assets/images/ilkokul/checkbox.svg"}
                        alt={"Doping Hafıza"}
                        loading="lazy" //! priority={true}
                        width={24}
                        height={24}
                        className=""
                      />
                    )
                  }
                  <div className="py-[1px] cursor-default">
                    <span onClick={(e:any) => {
                      e.stopPropagation();
                      setConsentDialog(true);
                    }} className="underline cursor-pointer font-medium text-[#FC6A00]">Açık Rıza Metni</span>&rsquo;ni ve Ticari İleti iletilmesini kabul ediyorum.
                  </div>
                </div>
              </form>

              <div className="flex justify-end w-full mt-3">
                <CommonButton width="100%" color="orange" onClick={handleSubmit} className={`px-2 transition-all ${isLoaded ? '' : '!grayscale !pointer-events-none'} ${sending ? '!grayscale !pointer-events-none' : ''}`}>
                  { _e('Bilgilerimi Gönder') }
                </CommonButton>
              </div>
            </div>
          )
        }

        <Dialog open={contractDialog} onOpenChange={setContractDialog}>
          <DialogContent className="z-[999] w-[calc(100svw-32px)] lg:w-2/3 xl:w-1/2 bg-white rounded-md overflow-hidden">
            <h2 className='m-0 py-[15px] ps-4 pe-9 text-red-600 font-semibold bg-cultured'>{ contracts.salesFormInformation.title }</h2>
            <ScrollArea className="h-[calc(100vh-240px)]">
              <div className="px-4 max-w-[calc(100svw-32px)] __user_form_dialog" dangerouslySetInnerHTML={purify(contracts.salesFormInformation.content)}></div>
            </ScrollArea>
          </DialogContent>
        </Dialog>

        <Dialog open={consentDialog} onOpenChange={setConsentDialog}>
          <DialogContent className="z-[999] w-[calc(100svw-32px)] lg:w-2/3 xl:w-1/2 bg-white rounded-md overflow-hidden">
            <h2 className='m-0 py-[15px] ps-4 pe-9 text-red-600 font-semibold bg-cultured'>{ contracts.consent.title }</h2>
            <ScrollArea className="h-[calc(100vh-240px)]">
              <div className="px-4 max-w-[calc(100svw-32px)] __user_form_dialog" dangerouslySetInnerHTML={purify(contracts.consent.content)}></div>
            </ScrollArea>
          </DialogContent>
        </Dialog>
      </div>
      <SimpleToaster ref={toasterRef} />
    </div>
  );
}