"use client";

import InfoCarousel from "@/components/InfoCarousel/InfoCarousel";
import InfoItem from "@/components/InfoCarousel/InfoItem";
import PackageCard from "@/components/PackageCard/PackageCard";
import { gtmSender, linkUrl, purify, rtbhSender } from "@/helpers";
import _e from "@/helpers/texts";
import Image from "next/image";
import SwipingTabs from "@/components/SwipingTabs/SwipingTabs";
import TabContent from "@/components/SwipingTabs/TabContent";
import InfoBox from "./InfoBox";
import InfoBtn from "./InfoBtn";
import VideoList from "./VideoList";
import PackageContent from "./PackageContent";
import { formatCurrency, formatENetValues, formatNumber, formatPackageEventItem, getECommerceId, insertENetValues } from "@/helpers/formatters";
import SinglePackage from "@/components/MostPreferredPackages/SinglePackage";
import { getCards } from "@/views/our-packages/package-categories/packages/helpers/statics";
import PackageUsageInformation from "./PackageUsageInformation";
import React, { useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import { durationMinutes } from "@/helpers/fetchers/package";
import JsonLd from "@/components/JsonLd/JsonLd";
import PackDayGroup from "@/components/PackDayCalc/PackDayGroup";
import BuyInfoDialog from "@/components/BuyInfoDialog/BuyInfoDialog";
import TestimonialFullList from "./TestimonialFullList";
import SpecList from "./SpecList";

export default function PackageMain({ data, category }: Record<string,any>) {
  const [ curriculumList, setCurriculumList ] = useState(data.curriculums.curriculum.map((item: Record<string,any>) => {
    const listItem = { ...item };
    listItem.units = [];
    return listItem;
  }));
  const dialogRef = useRef<any>(null);

  const [ isCurriculumLoaded, setIsCurriculumLoaded ] = useState(false);

  const pathname = usePathname();
  const pathRef = useRef<string>();

  const cards = getCards();
  
  let detailTabIndex = 1;

  if(data?.package_specs?.length > 0) detailTabIndex++;

  const testimonialVideos: Record<string,any>[] = [];
  const testimonials: Record<string,any>[] = [];

  if(data.videos?.length) detailTabIndex++;

  if(data.testimonials?.length) {
    detailTabIndex++;
    data.testimonials.forEach((item: Record<string,any>) => {
      if(item.video_url) {
        testimonialVideos.push({
          id: item.id,
          caption: item?.caption ?? item?.name ?? '',
          video_url: item.video_url,
          video_poster_url: item?.image_url ?? '',
        });
      } else {
        testimonials.push({ ...item });
      }
    });
  }

  const detailRequest = (index: number) => {
    window?.dispatchEvent(new CustomEvent('detailRequest', { detail: { detailTabIndex, index: index } }));
  };

  useEffect(() => {
    if(pathRef.current !== pathname) {
      const eventVal: Record<string,any> = {
        "event": "view_item",
        "ecommerce": {
          "currency": "TRY",
          "items": []
        }
      };

      eventVal.ecommerce.items.push(formatPackageEventItem(data, category.caption ?? ''));

      gtmSender({ ecommerce: null });
      gtmSender(eventVal);
      pathRef.current = pathname;
    }

    (async () => {
      try {
        const res = await fetch('/api/curriculum', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: data.external_id }),
        });
      
        if (res.status === 200) {
          const answer = await res.json();
          
          if(Array.isArray(answer?.curriculum)) setCurriculumList(answer?.curriculum?.map((item: Record<string,any>) => {
            return item;
          }));

          setIsCurriculumLoaded(true);
        }
      } catch(e) {}
    })();
  }, [
    pathname,
    data,
    category
  ]);

  useEffect(() => {
    rtbhSender({ eventType: 'offer', offerId: (getECommerceId(data))?.toString() });
  }, [ data ]);
  
  return (
    <>
      <BuyInfoDialog ref={dialogRef} />

      <main className="">
        <section className="container">
          <PackageCard data={ data } />
        </section>

        <section className="container">
          <SwipingTabs className="mt-4">
            {
              data?.package_specs?.length > 0 && (
                <TabContent title="Paket Özellikleri">
                  <SpecList specs={data.package_specs} />
                </TabContent>
              )
            }

            {
              curriculumList?.length && (
                <TabContent title="Pakette Neler Var?">
                  <InfoCarousel> {/* ! defaultItem={0} is used to show the first item by default */}
                    {
                      curriculumList.map((item: Record<string,any>, index: number) => (
                        <InfoItem key={item.curriculumId} title={item?.info?.use_original_caption ? item.curriculumName : (item?.info?.caption ?? item.curriculumName)} className="mt-5">
                          
                          {
                            !item?.info?.hide_buttons && (
                              <div className="flex justify-start flex-wrap gap-5 mb-4">
                                { item?.totalContentDuration ? (<InfoBox content="Ders Süresi" value={`${formatNumber(durationMinutes(item.totalContentDuration))} dk.`} icon="timer" />) : null }
                                { item?.totalInteractiveContentDuration ? (<InfoBox content="İnteraktif ders" value={`${formatNumber(durationMinutes(item.totalInteractiveContentDuration))} dk.`} icon="timer" />) : null }
                                { item?.totalContentCount ? (<InfoBox content="Ders sayısı" value={`${formatNumber(item.totalContentCount)}`} icon="play" />) : null }
                              </div>
                            )
                          }

                          <div className="mb-6 text-sm leading-loose" data-curriculum-id={item.curriculumId} dangerouslySetInnerHTML={purify(item?.info?.content ?? '')}></div>
                          
                          <div className={isCurriculumLoaded ? '' : 'opacity-0 pointer-events-none'}>
                            <InfoBtn title={_e(':name ders içeriklerini göster', { name: item?.info?.use_original_caption ? item.curriculumName : (item?.info?.caption ?? item.curriculumName) })} onClick={ () => detailRequest(index) } />
                          </div>
                        </InfoItem>
                      ))
                    }
                  </InfoCarousel>
                </TabContent>
              )
            }
              
            {/* Old usage */}
            {/* {
              data.testimonials?.length && (
                <TabContent title="Kullanıcı Yorumları">
                  { testimonialVideos.length > 0 ? (<VideoList data={testimonialVideos} />) : (<></>) }
                  { testimonials.length > 0 ? (<TestimonialList data={testimonials} />) : (<></>) }
                </TabContent>
              )
            } */}

            {/* New usage */}
            {
              data.testimonials?.length && (
                <TabContent title="Başarılar">
                  <TestimonialFullList testimonialList={testimonials} videoList={testimonialVideos} />
                </TabContent>
              )
            }

            {
              data.videos?.length && (
                <TabContent title="Örnek Videolar">
                  <VideoList data={data.videos} />
                </TabContent>
              )
            }

            {
              isCurriculumLoaded && curriculumList?.length && (
                <TabContent title="Ders İçerikleri">
                  <InfoCarousel trackDetailRequest={true}>
                    {
                      curriculumList.map((item: Record<string,any>) => (
                        <InfoItem key={item.curriculumId} title={item?.info?.use_original_caption ? item.curriculumName : (item?.info?.caption ?? item.curriculumName)} className="mt-5">
                          {
                            item?.info?.use_curriculum_content ? (
                              <div className="text-sm" dangerouslySetInnerHTML={purify(item?.info?.curriculum_content ?? '')}></div>
                            ) : (<PackageContent data={item} />)
                          }
                        </InfoItem>
                      ))
                    }
                  </InfoCarousel>
                </TabContent>
              )
            }
            
            {
              !(data?.prevent_sales && data?.coming_soon) && (
                <TabContent title="Taksit Seçenekleri">
                  <div className="bg-white p-8 rounded-lg shadow-md select-none">
                    <div className="mb-2 font-semibold">Vadesiz taksit fırsatları</div>
                    <div className="text-sm">
                      <p className="mb-2">
                        Doping Hafıza paketlerine vade farksız
                        taksit seçenekleriyle ulaşır, taksit taksit ödeme
                        yaparken vade farkı ödemezsiniz!
                      </p>
                      <p className="">
                        Bankanızın ülke genelinde uyguladığı taksit kısıtlamaları bulunuyorsa, ödeme adımında
                        farklı taksit sayılarıyla karşılaşabilirsiniz. Bu durumda bize çağrı merkezimiz
                        üzerinden ulaşıp, farklı bankaların uyguladığı taksit sayıları ile ilgili güncel bilgi
                        alabilirsiniz.
                      </p>
                    </div>

                    <div className="flex justify-start items-center flex-wrap -mx-4 mt-4">
                      {
                        cards.map((card) => (
                          <div key={card.id} className="flex px-4 py-3 w-full sm:w-1/2 lg:w-1/3">
                            <div className={`flex justify-between items-center px-4 w-full rounded-lg ${card.className}`}>
                              <div className={`relative w-full ${card.height}`}>
                                <Image
                                  src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/cc-logos/', card.image) ?? ''}
                                  alt={card.name}
                                  fill={true}
                                  sizes="100%"
                                  style={{ objectFit: 'contain', objectPosition: 'left center' }}
                                />
                              </div>

                              <div className="flex items-center">
                                <span className="text-[40px] font-extrabold me-2">{ card.installments }</span>
                                <span className="text-xl">{ _e('Taksit') }</span>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className="flex justify-start items-stretch flex-wrap mt-8 -mx-4 text-xs">
                    {
                      cards.map((card) => (
                        <div key={card.id} className="flex flex-col px-4 py-6 w-full sm:w-1/2 lg:w-1/3">
                          <div className="flex flex-col h-full rounded-lg overflow-hidden shadow-md">
                            <div className={`flex justify-between items-center px-4 w-full h-[60px] ${card.className}`}>
                              <div className={`relative w-full ${card.height}`}>
                                <Image
                                  src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/cc-logos/', card.image) ?? ''}
                                  alt={card.name}
                                  fill={true}
                                  sizes="100%"
                                  style={{ objectFit: 'contain', objectPosition: 'left center' }}
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-3 font-semibold text-center bg-slate-200">
                              <div className="px-2 py-2">Taksit</div>
                              <div className="px-2 py-2">Taksit Tutarı</div>
                              <div className="px-2 py-2">Toplam Tutar</div>
                            </div>

                            <div className="flex-grow">
                              {
                                card.instList.map((inst: number) => (
                                  <div key={inst} className={`grid grid-cols-3 text-center border-t-[1px] border-slate-200 bg-white [&:nth-child(odd)]:bg-gray-50`}>
                                    <div className="px-2 py-4">{ inst === 1 ? _e('Tek Çekim') : inst }</div>
                                    <div className="px-2 py-4">{ formatCurrency(data?.price / inst) }</div>
                                    <div className="px-2 py-4">{ formatCurrency(data?.price) }</div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>

                </TabContent>
              )
            }
          </SwipingTabs>
        </section>

        {
          !(data?.prevent_sales && data?.coming_soon) && (
            <section className="container">
              <InfoCarousel className="mt-8">
                <InfoItem title={_e('Paket kullanım bilgileri')} className="mt-2">
                  <PackageUsageInformation device_combination={data.device_combination} usage_info={data?.usage_info ? data.usage_info : (data?.package_category?.usage_info ? data.package_category.usage_info : undefined)} />
                </InfoItem>
              </InfoCarousel>
            </section>
          )
        }

        {
          data?.curriculums?.curriculum?.length > 0 && !data?.hide_calc && (
            <section
              className="__day-calc-list-top-container bg-charcoal mt-8 !pt-12 !pb-6"
              style={{ backgroundImage: 'url(' + process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/global/time-calculator-bg.png' + ')' }}
            >
              <div className="container">
                <PackDayGroup
                  heading={{
                    'caption': '<strong>Doping Hafıza</strong> İle Kaç Günde Biter?',
                    'subcap': _e('Doping Hafızayı kullanarak <strong>:caption</strong> kaç günde bitirebileceğinizi hesaplayabilirsiniz.', { 'caption': data?.caption ?? '' })
                  }}
                  relatedPackage={data}
                  hidePackage={true}
                />
              </div>
            </section>
          )
        }

        {
          category.packages?.length > 0 ? (
            <section className="relative mt-12">
              <div
                className="hidden md:block absolute left-0 top-0 w-full pb-[26%] min-h-[400px] bg-white z-0"
                style={{
                  clipPath: 'polygon(7% 0, 100% 0, 100% 100%, 0 100%)'
                }}
              ></div>
              <div className="block md:hidden absolute left-0 top-0 w-full pb-[26%] min-h-[400px] bg-white z-0"></div>

              <div className="relative pt-4 z-10">
                <SinglePackage dialogRef={dialogRef} subCap={ _e('İlgini çekebilir') } packageData={category} rowsPerPage={-1} hasCategoryLink={category?.has_category_link} hideRemaining={true} gtmListId="related_packages" />
              </div>
            </section>
          ) : (<></>)
        }

        <JsonLd data={{
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.caption,
          "image": linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.image_url),
          "description": insertENetValues(data?.content ?? '', formatENetValues({ ...(data?.curriculums?.credits ?? {}), ...{ Sezon: data.season, 'Paket Adı': data.caption } }), true),
          "brand": {
            "@type": "Brand",
            "name": "Doping Hafıza"
          },
          "offers": {
            "@type": "Offer",
            "priceCurrency": "TRY",
            "price": data.price,
            "url": pathname
          }
        }} />
      </main>
    </>
  );
}
